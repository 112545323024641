import { message } from 'antd'
import { swalError } from 'components/SwalError/SwalError'
import { IResponseBase } from 'ecp/repositories/Repository'
import { IConfigurationMaxSolicitationResponse, IMaxSolicitationConfigurationForm } from 'ecpf/repositories/ConfigurationECPFRepository'
import { ConfigurationService, IConfigurationECPFBody, IConfigurationECPFResponse } from 'ecpf/services/configurationService'
import { useCallback, useState } from 'react'

export function useConfigurationWhatsappECPF () {
  const [error, setError] = useState<Partial<IResponseBase['data']>>()
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const fetchConfiguration = useCallback(async (abortController: AbortController, fnCallback: (configuration: IConfigurationECPFResponse) => void) => {
    try {
      setLoading(true)

      const configurationService = new ConfigurationService()
      const configuration = await configurationService.lastWhatsappConfiguration({ signal: abortController.signal })

      fnCallback(configuration)
      setError(undefined)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  const updateConfiguration = useCallback(async (configuration: IConfigurationECPFBody) => {
    try {
      setSubmitLoading(true)

      const configurationService = new ConfigurationService()
      const response = await configurationService.createWhatsappConfiguration(configuration)
      message.success(response?.data?.message)
    } catch (err) {
      swalError({ err })
    } finally {
      setSubmitLoading(false)
    }
  }, [])

  return {
    fetchConfiguration,
    updateConfiguration,
    error,
    loading,
    submitLoading
  }
}

export function useConfigurationMaxSolicitation () {
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const fetchMaxSolicitation = useCallback(async (productId: string, fnCallback: (configuration: IConfigurationMaxSolicitationResponse) => void) => {
    try {
      setLoading(true)
      const configurationService = new ConfigurationService()
      const configuration = await configurationService.getMaxSolicitation(productId)
      const configurationMaxSolicitation = configuration?.data?.data?.configurationMaxSolicitation

      if (configurationMaxSolicitation) fnCallback(configurationMaxSolicitation)
    } catch (err) {
      message.error(err?.message)
      fnCallback({ maxSolicitation: 0 })
    } finally {
      setLoading(false)
    }
  }, [])

  const updateMaxSolicitation = useCallback(async (body: IMaxSolicitationConfigurationForm) => {
    try {
      setSubmitLoading(true)
      const configurationService = new ConfigurationService()
      const response = await configurationService.updateMaxSolicitation(body)
      message.success(response?.data?.message)
    } catch (err) {
      swalError({ err })
    } finally {
      setSubmitLoading(false)
    }
  }, [])

  return {
    fetchMaxSolicitation,
    updateMaxSolicitation,
    loading,
    submitLoading
  }
}
