import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Col, Form, Radio, Row, Select, Spin, Switch } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { SwitchProps } from 'antd/lib/switch'
import InstructionsSvg from 'assets/reactSvgs/InstructionsSvg/InstructionsSvg'
import ErrorDetailsECPF, { ErrorDetailsSimulationECPF, ErrorListContainerECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import { InputMoney } from 'components/IntlInput/IntlInput'
import { LoadingCyclicTexts } from 'components/LoadingCyclicTexts/LoadingCyclicTexts'
import Modal from 'components/Modal/Modal'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import { R$ } from 'ecp/app/Proposals/proposalFunctions'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import { IResponseBase } from 'ecp/repositories/Repository'
import { useConfigurationWhatsappECPF } from 'ecpf/app/ConfigurationECPF/hooks/useConfigurationECPF'
import { useProposalFlow } from 'ecpf/hooks/useProposalFlow'
import { IConfigurationECPFResponse } from 'ecpf/services/configurationService'
import { ConvenantLimitNotFound } from 'ecpf/services/proposalService'
import { useAuth } from 'hooks'
import { useUserPermissions } from 'hooks/usePermissions'
import { PermissionModel } from 'models/PermissionModel'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import format from 'utils/format'
import { isDevelopment, isLocal } from 'utils/globals'
import mask from 'utils/masks'
import { isNullish } from 'utils/utils'
import { useProposalContextECPF } from '../../hooks/useProposalContextECPF'
import { useValidateSimulationMaxSolicitation } from '../../hooks/useSimulationECPF'
import { useSimulationFlowEcpf } from '../../hooks/useSimulationFlowECPF'
import { returnProposalType } from '../../ProposalECPFFunctions'
import { MetaCalculo } from '../../ProposalECPFInterfaces'
import ButtonsStepECPF from '../ButtonsStepECPF/ButtonsStepECPF'
import FormTitleECPF from '../FormTitleECPF/FormTitleECPF'
import ProposalCardECPF from '../ProposalCardECPF/ProposalCardECPF'

export function SimulationCard ({ metaCalculo, value, title }:{ metaCalculo: MetaCalculo, value: string, title: string }) {
  const installmentChecked = metaCalculo === MetaCalculo.ValorParcela

  return (
    <aside className={`simulation-flow-ecpf__card ${installmentChecked && 'simulation-flow-ecpf__card--installment'}`}>
      <p className={`simulation-flow-ecpf__card-title ${installmentChecked && 'simulation-flow-ecpf__card-title--installment'}`}>{title}</p>
      <p className={`simulation-flow-ecpf__card-value ${installmentChecked && 'simulation-flow-ecpf__card-value--installment'}`}>{value}</p>
    </aside>
  )
}

export function SimulationMinMaxInput ({ min, max, loading, useMinValue }: { min?: ReactNode, max?: ReactNode, loading?: boolean, useMinValue?: boolean }) {
  const spin = <Spin size='small' indicator={<LoadingOutlined className='simulation-flow-ecpf-min-max__spin-icon' />} className='ml-1' />

  if (loading) {
    return (
      <div className='simulation-flow-ecpf-min-max'>
        <span>Min: {spin}</span><span>Máx: {spin}</span>
      </div>
    )
  }

  return (
    <div className='simulation-flow-ecpf-min-max'>
      <span>{useMinValue ? `Min: ${min || '-'}` : ''}</span><span>Máx: {max || '-'}</span>
    </div>
  )
}

export function InsuranceSwitch ({ ...rest }: SwitchProps) {
  return (
    <>
      <Switch
        {...rest}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
      <b className='ml-1'>Incluir Seguro</b>
    </>
  )
}

export function UnLockLimitButton ({ ...rest }: SwitchProps) {
  return (
    <>
      <Switch
        {...rest}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
      <b className='ml-1'>Limite de refinanciamento</b>
    </>
  )
}

export function ProposalECPFInsuranceTooltip ({
  tooltipMessage,
  loading,
  error
}: { loading: boolean, error?: Partial<IResponseBase['data']>, tooltipMessage?: IConfigurationECPFResponse['seguroTooltipMessage'] }) {
  const [visible, setVisible] = useState(false)

  if (loading) {
    return <Spin />
  }

  return (
    <>
      <Modal
        visible={visible}
        cancelText={null}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <h3>Informações seguro</h3>

        <StringToHtml
          value={error?.message ?? tooltipMessage ?? ''}
        />

        <div className='text-right mt-3'>
          <Button
            type='primary'
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setVisible(false)
            }}
          >
            Fechar
          </Button>
        </div>
      </Modal>

      <button
        type='button'
        className='simulation-flow-ecpf__tooltip-insurance'
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          setVisible(true)
        }}
      >
        Saber mais?
      </button>
    </>
  )
}

export function ProposalECPFWhatsAppComercialLink ({
  loading,
  error,
  whatsAppNumber
}: { loading: boolean, error?: Partial<IResponseBase['data']>, whatsAppNumber?: IConfigurationECPFResponse['whatsappComercialNumber'] }) {
  const whatsappStartMessage = 'Não consegui simular o valor máximo do meu empréstimo consignado privado, poderia me ajudar?'

  const redStyle = { color: 'var(--red-status)' }

  if (loading) return <Spin />

  if (error) return <span style={redStyle}>{error?.message}</span>

  if (!whatsAppNumber) return <span style={redStyle}>Número não encontrado</span>

  return (
    <a
      className='link-style'
      rel="noreferrer"
      target='_blank'
      href={`https://wa.me/${whatsAppNumber}?text=${encodeURI(whatsappStartMessage)}.`}
    >
      {mask(whatsAppNumber, 'phone', true)}
    </a>
  )
}

export const ProceedWithoutLimitsButtonWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className='text-center mt-6'>{children}</div>
  )
}

export const ProceedWithoutLimitsButton = ({ ...rest }: ButtonProps) => {
  return (
    <Button
      type='primary'
      {...rest}
    >
      Prosseguir sem valores máximos
    </Button>
  )
}

function SimulationFlowECPF () {
  const { onNextStep, proposalType, currentStep } = useProposalContextECPF()
  const [configuration, setConfiguration] = React.useState<IConfigurationECPFResponse>()
  const simulationFlowAbortController = useRef(new AbortController()).current

  const { customerFlow, clientCovenant, getClientCovenantConditional, resetSimulationFlow, simulationFlow, simulationFlowMinMax } = useProposalFlow()
  const hasTelevendasPermission = true

  const {
    formRef,
    onUpdateMinMaxByQuantidadeParcelas,
    error,
    onFetchLimits,
    onGetClientCovenantConditional,
    loading,
    onFinish,
    loadingSubmit,
    loadingMaxValues,
    onChangeMetaCalculo,
    metaCalculo,
    onProceedWithoutLimits,
    parcelasDisponiveis
  } = useSimulationFlowEcpf()

  const metaCalculoParcela = metaCalculo === MetaCalculo.ValorParcela
  const metaCalculoSolicitado = metaCalculo === MetaCalculo.ValorSolicitado

  const { fetchConfiguration, loading: configurationLoading, error: errorConfiguration } = useConfigurationWhatsappECPF()
  const { onValidateMaxSolicitation, loadingValidation } = useValidateSimulationMaxSolicitation()
  const { userPermissions } = useUserPermissions()
  const user = useAuth()

  useEffect(() => {
    const abortController = new AbortController()
    const configurationCallback = (configuration: IConfigurationECPFResponse) => {
      setConfiguration(configuration)
    }

    fetchConfiguration(abortController, configurationCallback)
    return () => abortController.abort()
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    if (!simulationFlowMinMax) {
      if (proposalType && customerFlow && clientCovenant) {
        onFetchLimits({
          customerFlow,
          proposalType,
          clientCovenant
        }, abortController)
      }
    }

    if (simulationFlowMinMax && !simulationFlow) {
      formRef.setFieldsValue({
        condicaoCredito: {
          valorSolicitado: simulationFlowMinMax?.maxValorSolicitado,
          valorParcela: simulationFlowMinMax?.maxValorParcela,
          quantidadeParcelas: simulationFlowMinMax.maxQuantidadeParcelas
        }
      })
    }

    return () => {
      abortController.abort()
    }
  }, [
    clientCovenant?.origens?.codigoEmpregador,
    proposalType,
    customerFlow?.cliente?.dataNascimento,
    currentStep
  ])

  const handleNextStep = () => {
    const abortController = new AbortController()
    const config = {
      params: {
        tipoOperacao: returnProposalType(proposalType),
        valorSolicitado: getClientCovenantConditional(proposalType)?.valorSolicitado
      },
      signal: abortController.signal
    }

    onValidateMaxSolicitation(config, onNextStep)

    return () => {
      abortController.abort()
    }
  }

  useEffect(() => {
    onGetClientCovenantConditional()
  }, [currentStep])

  if (error && error instanceof ConvenantLimitNotFound) {
    return (
      <EcpCard className='simulation-flow-ecpf__loading-card'>
        <EcpCard className='simulation-flow-ecpf__simulation-limit-container'>
          <ErrorDetailsSimulationECPF
            whatsAppLink={
              <ProposalECPFWhatsAppComercialLink
                loading={configurationLoading}
                error={errorConfiguration}
                whatsAppNumber={configuration?.whatsappComercialNumber}
              />
            }
          />

          {(isLocal || isDevelopment || PermissionModel.canSimulateWithoutLimits(user, userPermissions)) && (
            <div className='mt-6 w-100 text-center'>
              <ErrorListContainerECPF
                containerClassName='simulation-flow-ecpf__error-container'
                fullHeightContainer
                error={error}
              />
            </div>
          )}

          {PermissionModel.canSimulateWithoutLimits(user, userPermissions) && (
            <ProceedWithoutLimitsButtonWrapper>
              <ProceedWithoutLimitsButton onClick={onProceedWithoutLimits} />
            </ProceedWithoutLimitsButtonWrapper>
          )}
        </EcpCard>
      </EcpCard>
    )
  }

  if (error) {
    return (
      <EcpCard>
        <ErrorDetailsECPF
          fullHeightContainer
          title='Ops, parece que algo deu errado'
          error={error}
        />

        {PermissionModel.canSimulateWithoutLimits(user, userPermissions) && (
          <ProceedWithoutLimitsButtonWrapper>
            <ProceedWithoutLimitsButton onClick={onProceedWithoutLimits} />
          </ProceedWithoutLimitsButtonWrapper>
        )}
      </EcpCard>
    )
  }

  if (loading) {
    return (
      <EcpCard className='simulation-flow-ecpf__loading-card'>
        <div className='text-center'>
          <div className='simulation-flow-ecpf__loading-instruction-svg'>
            <InstructionsSvg />
          </div>

          <LoadingCyclicTexts
            milissecondsCycle={4000}
            description='Estamos preparando a simulação...'
            textOptions={[
              'Conferindo limites do convênio',
              'Calculando os valores máximos',
              'Validando regras'
            ]}
          />
        </div>
      </EcpCard>
    )
  }

  return (
    <div className='simulation-flow-ecpf'>
      <Form
        form={formRef}
        initialValues={{
          condicaoCredito: {
            metaCalculo: MetaCalculo.ValorParcela
          }
        }}
        layout='vertical'
        onFinish={(values) => {
          onFinish({
            simulation: values,
            abortController: simulationFlowAbortController,
            simulationFlowMinMax
          })
        }}
        onValuesChange={(changedValues) => {
          if (Object.keys(changedValues?.condicaoCredito).length === 1 && changedValues?.condicaoCredito?.metaCalculo) {
            return null
          } else {
            resetSimulationFlow()
          }
        }}
      >
        <ProposalCardECPF>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <FormTitleECPF>Valores</FormTitleECPF>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name={['condicaoCredito', 'metaCalculo']}
                label='Quero definir o valor pelo(a)'
                className='simulation-flow-ecpf__form-item-meta-calculo'
              >
                <Radio.Group
                  optionType='button'
                  disabled={loadingSubmit || loadingMaxValues}
                  onChange={event => onChangeMetaCalculo(event.target.value)}
                  options={[
                    { label: 'Total desejado', value: MetaCalculo.ValorParcela },
                    { label: 'Parcela desejada', value: MetaCalculo.ValorSolicitado }
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} className='simulation-flow-ecpf__insurance'>
              <Form.Item
                label={
                  <ProposalECPFInsuranceTooltip
                    loading={configurationLoading}
                    error={errorConfiguration}
                    tooltipMessage={configuration?.seguroTooltipMessage}
                  />
                }
                name={['condicaoCredito', 'seguro']}
                valuePropName='checked'
                className='simulation-flow-ecpf__form-item-meta-calculo'
                initialValue={true}
              >
                <InsuranceSwitch
                  disabled={loadingMaxValues || loadingSubmit}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} style={{ display: metaCalculoParcela ? 'block' : 'none' }}>
              <Form.Item
                name={['condicaoCredito', 'valorSolicitado']}
                className='mb-0'
                label='Valor total desejado'
              >
                <InputMoney
                  classNameInput='text-center'
                />
              </Form.Item>

              <SimulationMinMaxInput
                loading={loadingMaxValues}
                min={R$(simulationFlowMinMax?.minValorSolicitado)}
                max={simulationFlowMinMax?.maxValorSolicitado ? R$(simulationFlowMinMax?.maxValorSolicitado) : '-'}
              />
            </Col>

            <Col xs={24} md={12} style={{ display: metaCalculoParcela ? 'none' : 'block' }}>
              <Form.Item
                name={['condicaoCredito', 'valorParcela']}
                className='mb-0'
                label='Valor parcela desejada'
              >
                <InputMoney
                  classNameInput='text-center'
                />
              </Form.Item>

              <SimulationMinMaxInput
                loading={loadingMaxValues}
                min={R$(simulationFlowMinMax?.minValorParcela)}
                max={simulationFlowMinMax?.maxValorParcela ? R$(simulationFlowMinMax?.maxValorParcela) : '-'}
              />
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label='Quantidade de parcelas'
                className='mb-0'
                initialValue={parcelasDisponiveis[0].value}
                name={['condicaoCredito', 'quantidadeParcelas']}
              >
                <Select
                  disabled={loadingMaxValues || loadingSubmit}
                  showSearch
                  className='text-center'
                  optionFilterProp='label'
                  options={parcelasDisponiveis}
                  onSelect={
                    (parcelas) => {
                      if (!hasTelevendasPermission) {
                        onUpdateMinMaxByQuantidadeParcelas(
                          parcelas as number,
                          formRef.getFieldValue(['condicaoCredito', 'valorParcela']),
                          formRef.getFieldValue(['condicaoCredito', 'valorSolicitado']),
                          simulationFlowAbortController
                        )
                      }
                    }
                  }
                />
              </Form.Item>

              <SimulationMinMaxInput
                loading={loadingMaxValues}
                min={1}
                max={simulationFlowMinMax?.maxQuantidadeParcelas}
              />
            </Col>

            {(isNullish(getClientCovenantConditional(proposalType)?.valorParcela) || loadingSubmit === true) ? (
              <Col span={24}>
                <div className='text-right'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='simulation-flow-ecpf__simulate-button'
                    loading={loadingSubmit}
                    disabled={loadingMaxValues}
                  >
                    Simular
                  </Button>
                </div>
              </Col>
            ) : (
              <Col xs={24} className='simulation-flow-ecpf__card-container'>
                {metaCalculoSolicitado && (
                  <SimulationCard
                    metaCalculo={MetaCalculo.ValorSolicitado}
                    title='Valor total a ser contratado'
                    value={isNullish(getClientCovenantConditional(proposalType)?.valorSolicitado) ? 'Insira um valor' : format.formatBRL(getClientCovenantConditional(proposalType)?.valorSolicitado)}
                  />
                )}

                <SimulationCard
                  metaCalculo={MetaCalculo.ValorParcela}
                  title='Valor da parcela a ser paga'
                  value={isNullish(getClientCovenantConditional(proposalType)?.valorParcela) ? 'Insira um valor' : format.formatBRL(getClientCovenantConditional(proposalType)?.valorParcela)}
                />
              </Col>
            )}
          </Row>
        </ProposalCardECPF>

        <ButtonsStepECPF
          disabled={isNullish(simulationFlow) || loadingSubmit === true}
          onSubmit={handleNextStep}
          loading={loadingValidation}
          htmlTypeSubmitButton='button'
        />
      </Form>
    </div>
  )
}

export default SimulationFlowECPF
