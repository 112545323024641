import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import React, { useEffect, useState } from 'react'

export function LoadingCyclicTexts (props: {
  description: string,
  textOptions: string[],
  milissecondsCycle: number
}) {
  const { description, textOptions, milissecondsCycle } = props

  const [loadingState, setLoadingState] = useState(0)
  const [animationClass, setAnimationClass] = useState('tracking-in-expand')

  useEffect(() => {
    let timeout: NodeJS.Timeout
    const timer = setInterval(() => {
      setLoadingState((prevState) => (prevState + 1) % textOptions.length)
      setAnimationClass('tracking-in-expand')
      timeout = setTimeout(() => {
        setAnimationClass('')
      }, 1000)
    }, milissecondsCycle)

    return () => {
      clearInterval(timer)
      clearTimeout(timeout)
    }
  }, [])

  const loadingText = textOptions[loadingState]

  return (
    <div>
      <h1 className={'loading-cyclic-texts__title ' + animationClass}>{loadingText}</h1>
      <p className="loading-cyclic-texts__description">{description}</p>

      <LoadingSpinner className='mt-3' />
    </div>
  )
}
