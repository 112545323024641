import { Button, Col, Form, Radio, Row, Select } from 'antd'
import InstructionsSvg from 'assets/reactSvgs/InstructionsSvg/InstructionsSvg'
import ErrorDetailsECPF, { ErrorDetailsSimulationECPF, ErrorListContainerECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import { InputMoney } from 'components/IntlInput/IntlInput'
import { LoadingCyclicTexts } from 'components/LoadingCyclicTexts/LoadingCyclicTexts'
import { R$ } from 'ecp/app/Proposals/proposalFunctions'
import DebitBalanceModal from 'ecp/app/Renegotiation/components/DebitBalanceModal/DebitBalanceModal'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import ValueCard from 'ecp/components/ValueCard/ValueCard'
import { useConfigurationWhatsappECPF } from 'ecpf/app/ConfigurationECPF/hooks/useConfigurationECPF'
import { useProposalFlow } from 'ecpf/hooks/useProposalFlow'
import ConfigurationECPFRepository from 'ecpf/repositories/ConfigurationECPFRepository'
import { IContractECPF } from 'ecpf/repositories/OperationsECPFRepository'
import { IConfigurationECPFResponse } from 'ecpf/services/configurationService'
import { ConvenantLimitNotFound } from 'ecpf/services/proposalService'
import UserModels from 'egi/models/UserModels'
import { useAuth } from 'hooks'
import { useUserPermissions } from 'hooks/usePermissions'
import { PermissionModel } from 'models/PermissionModel'
import React, { useEffect, useRef, useState } from 'react'
import { ISelectContractsFlow } from 'store/modules/proposalFlow/actions'
import format from 'utils/format'
import { isDevelopment, isLocal } from 'utils/globals'
import { isNullish } from 'utils/utils'
import { useProposalContextECPF } from '../../hooks/useProposalContextECPF'
import { useValidateSimulationMaxSolicitation } from '../../hooks/useSimulationECPF'
import { useSimulationFlowEcpf } from '../../hooks/useSimulationFlowECPF'
import { returnProposalType } from '../../ProposalECPFFunctions'
import { MetaCalculo } from '../../ProposalECPFInterfaces'
import ButtonsStepECPF from '../ButtonsStepECPF/ButtonsStepECPF'
import FormTitleECPF from '../FormTitleECPF/FormTitleECPF'
import ProposalCardECPF from '../ProposalCardECPF/ProposalCardECPF'
import { ProposalECPFWhatsAppComercialLink, ProposalECPFInsuranceTooltip, InsuranceSwitch, SimulationMinMaxInput, SimulationCard, ProceedWithoutLimitsButtonWrapper, ProceedWithoutLimitsButton, UnLockLimitButton } from '../SimulationFlowECPF/SimulationFlowECPF'

const formatSelectedContracts = (selectedContracts: (IContractECPF | undefined)[] | undefined) => {
  if (!selectedContracts) return []
  return selectedContracts.map((selectedContract) => {
    return {
      saldo_devedor: selectedContract?.saldoData || 0
    }
  })
}

const getSelectedContractsData = (selectContractsFlow: ISelectContractsFlow) => {
  const selectedContracts = selectContractsFlow?.selectedContracts?.map((selectedContract) => {
    return (selectContractsFlow?.contracts ?? []).find((contract) => contract.numeroContrato === selectedContract)
  })

  return selectedContracts
}

export const getSaldoDevedorTotal = (selectContractsFlow?: ISelectContractsFlow) => {
  if (!selectContractsFlow || !selectContractsFlow.selectedContracts || !selectContractsFlow.contracts) return 0

  const selectedContracts = getSelectedContractsData(selectContractsFlow)

  const saldoDevedorToal = selectedContracts?.reduce((acc, contract) => {
    return acc + (contract?.saldoData || 0)
  }, 0) ?? 0

  return saldoDevedorToal
}

function RefinancingSimulationFlowECPF () {
  const { onNextStep, proposalType, currentStep } = useProposalContextECPF()
  const [showDebitBalanceModal, setShowDebitBalanceModal] = useState(false)
  const [configuration, setConfiguration] = React.useState<IConfigurationECPFResponse>()
  const { userPermissions } = useUserPermissions()
  const user = useAuth()

  const { customerFlow, clientCovenant, getClientCovenantConditional, resetSimulationFlow, simulationFlow, simulationFlowMinMax, selectContractsFlow } = useProposalFlow()
  const simulationFlowAbortController = useRef(new AbortController()).current

  const {
    formRef,
    onUpdateMinMaxByQuantidadeParcelas,
    error,
    onFetchLimits,
    onGetClientCovenantConditional,
    loading,
    onFinish,
    loadingSubmit,
    loadingMaxValues,
    onChangeMetaCalculo,
    metaCalculo,
    parcelasDisponiveis,
    onProceedWithoutLimits
  } = useSimulationFlowEcpf()

  const { fetchConfiguration, loading: configurationLoading, error: errorConfiguration } = useConfigurationWhatsappECPF()
  const { onValidateMaxSolicitation, loadingValidation } = useValidateSimulationMaxSolicitation()

  const metaCalculoParcela = metaCalculo === MetaCalculo.ValorParcela
  const metaCalculoSolicitado = metaCalculo === MetaCalculo.ValorSolicitado
  const [minValue, setMinValue] = useState<number>()
  const [useMinValue, setUseMinValue] = useState<boolean | undefined>(true)
  const saldoDevedor = getSaldoDevedorTotal(selectContractsFlow)

  const onUseMinValueSend = async (useMinValue: boolean) => {
    setUseMinValue(useMinValue)
    await ConfigurationECPFRepository.postMinimumRefinancingValue({ useMinValue })
  }

  useEffect(() => {
    const fetchMinValue = async () => {
      try {
        const response = await ConfigurationECPFRepository.getMinimumRefinancingValue()
        setUseMinValue(response?.data?.data?.configuration?.useMinValue)
        setMinValue(response?.data?.data?.configuration?.minValue)
      } catch (error) {
        console.error('Error fetching minimum refinancing value', error)
      }
    }
    fetchMinValue()
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    if (!simulationFlowMinMax) {
      if (proposalType && customerFlow && clientCovenant) {
        onFetchLimits({
          customerFlow,
          proposalType,
          clientCovenant
        }, abortController)
      }
    }

    if (simulationFlowMinMax && !simulationFlow) {
      formRef.setFieldsValue({
        condicaoCredito: {
          valorSolicitado: simulationFlowMinMax?.maxValorSolicitado,
          valorParcela: simulationFlowMinMax?.maxValorParcela,
          quantidadeParcelas: simulationFlowMinMax.maxQuantidadeParcelas,
          useMinValue: useMinValue
        },
        saldoDevedor: saldoDevedor
      })
    }

    return () => {
      abortController.abort()
    }
  }, [
    clientCovenant?.origens?.codigoEmpregador,
    proposalType,
    customerFlow?.cliente?.dataNascimento,
    useMinValue,
    saldoDevedor
  ])

  useEffect(() => {
    const abortController = new AbortController()
    const configurationCallback = (configuration: IConfigurationECPFResponse) => {
      setConfiguration(configuration)
    }

    fetchConfiguration(abortController, configurationCallback)
    return () => abortController.abort()
  }, [])

  useEffect(() => {
    onGetClientCovenantConditional()
  }, [currentStep])

  const handleNextStep = () => {
    const abortController = new AbortController()
    const config = {
      params: {
        tipoOperacao: returnProposalType(proposalType),
        valorSolicitado: getClientCovenantConditional(proposalType)?.valorSolicitado
      },
      signal: abortController.signal
    }

    onValidateMaxSolicitation(config, onNextStep)

    return () => {
      abortController.abort()
    }
  }

  if (error && error instanceof ConvenantLimitNotFound) {
    return (
      <EcpCard className='simulation-flow-ecpf__loading-card'>
        <EcpCard className='simulation-flow-ecpf__simulation-limit-container'>

          <ErrorDetailsSimulationECPF
            whatsAppLink={
              <ProposalECPFWhatsAppComercialLink
                loading={configurationLoading}
                error={errorConfiguration}
                whatsAppNumber={configuration?.whatsappComercialNumber}
              />
            }
          />

          {(isLocal || isDevelopment || PermissionModel.canSimulateWithoutLimits(user, userPermissions)) && (
            <div className='mt-6 w-100 text-center'>
              <ErrorListContainerECPF
                containerClassName='simulation-flow-ecpf__error-container'
                fullHeightContainer
                error={error}
              />
            </div>
          )}

          {PermissionModel.canSimulateWithoutLimits(user, userPermissions) && (
            <ProceedWithoutLimitsButtonWrapper>
              <ProceedWithoutLimitsButton onClick={onProceedWithoutLimits} />
            </ProceedWithoutLimitsButtonWrapper>
          )}
        </EcpCard>
      </EcpCard>
    )
  }

  if (error) {
    return (
      <EcpCard>
        <ErrorDetailsECPF
          title='Ops, parece que algo deu errado'
          error={error}
        />

        {PermissionModel.canSimulateWithoutLimits(user, userPermissions) && (
          <ProceedWithoutLimitsButtonWrapper>
            <ProceedWithoutLimitsButton onClick={onProceedWithoutLimits} />
          </ProceedWithoutLimitsButtonWrapper>
        )}
      </EcpCard>
    )
  }

  if (loading) {
    return (
      <EcpCard className='simulation-flow-ecpf__loading-card'>
        <div className='text-center'>
          <div className='simulation-flow-ecpf__loading-instruction-svg'>
            <InstructionsSvg />
          </div>

          <LoadingCyclicTexts
            milissecondsCycle={4000}
            description='Estamos preparando a simulação...'
            textOptions={[
              'Conferindo limites do convênio',
              'Calculando os valores máximos',
              'Validando regras'
            ]}
          />
        </div>
      </EcpCard>
    )
  }

  return (
    <div className='simulation-flow-ecpf'>
      <DebitBalanceModal
        contracts={selectContractsFlow ? formatSelectedContracts(getSelectedContractsData(selectContractsFlow)) : []}
        visible={showDebitBalanceModal}
        onVisibleChange={setShowDebitBalanceModal}
        debitBalanceTotal={getSaldoDevedorTotal(selectContractsFlow)}
      />

      <ProposalCardECPF className='mb-3'>
        <h2 className='renegotiation-create__sub-title mb-3'>Detalhes</h2>
        <p className='mb-2'>
          <b>{selectContractsFlow?.selectedContracts?.length || 0} Contratos selecionados</b>
          <Button
            type='link'
            className='ml-1 p'
            onClick={() => setShowDebitBalanceModal(true)}
          >
            ver mais
          </Button>
        </p>

        <Row gutter={[15, 15]}>
          <Col lg={12} sm={24} xs={24}>
            <ValueCard
              label='Valor máximo permitido da operação'
              value={R$(simulationFlowMinMax?.maxValorSolicitado)}
              backGroundColor={'var(--light-gray)'}
            />
          </Col>

          <Col lg={12} sm={24} xs={24}>
            <ValueCard
              label='Saldo devedor total'
              backGroundColor='var(--light-gray)'
              value={
                <p
                  className='renegotiation-create__debit-balance-value'
                >
                  {R$(getSaldoDevedorTotal(selectContractsFlow))}
                </p>
              }
            />
          </Col>
        </Row>
      </ProposalCardECPF>

      <Form
        form={formRef}
        initialValues={{
          condicaoCredito: {
            metaCalculo: MetaCalculo.ValorParcela
          },
          saldoDevedor: saldoDevedor
        }}
        layout='vertical'
        onFinish={(values) => {
          values.saldoDevedor = saldoDevedor
          onFinish({
            simulation: values,
            abortController: simulationFlowAbortController,
            simulationFlowMinMax
          })
        }}
        onValuesChange={(changedValues) => {
          if (
            changedValues?.condicaoCredito &&
            Object.keys(changedValues.condicaoCredito).length === 1 &&
            changedValues.condicaoCredito.metaCalculo
          ) {
            return null
          } else {
            resetSimulationFlow()
          }
        }}
      >
        <ProposalCardECPF>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <FormTitleECPF>Valores</FormTitleECPF>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name={['condicaoCredito', 'metaCalculo']}
                label='Quero definir o valor pelo(a)'
                className='simulation-flow-ecpf__form-item-meta-calculo'
              >
                <Radio.Group
                  optionType='button'
                  disabled={loadingSubmit || loadingMaxValues}
                  onChange={event => onChangeMetaCalculo(event.target.value)}
                  options={[
                    { label: 'Total desejado', value: MetaCalculo.ValorParcela },
                    { label: 'Parcela desejada', value: MetaCalculo.ValorSolicitado }
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} className='simulation-flow-ecpf__insurance'>
              <Form.Item
                label={
                  <ProposalECPFInsuranceTooltip
                    loading={configurationLoading}
                    error={errorConfiguration}
                    tooltipMessage={configuration?.seguroTooltipMessage}
                  />
                }
                name={['condicaoCredito', 'seguro']}
                valuePropName='checked'
                className='simulation-flow-ecpf__form-item-meta-calculo'
                initialValue={true}
              >
                <InsuranceSwitch
                  disabled={loadingMaxValues || loadingSubmit}
                />
              </Form.Item>

              {UserModels.isMaster(user.level) && <Form.Item
                label={<span></span>}
                name={['useMinValue']}
                valuePropName='useMinValue'
                className='simulation-flow-ecpf__form-item-meta-calculo'
              >
                <UnLockLimitButton
                  checked={useMinValue}
                  disabled={loadingMaxValues || loadingSubmit}
                  onChange={(value) => onUseMinValueSend(value)}
                />
              </Form.Item>}

            </Col>

            <Col xs={24} md={12} style={{ display: metaCalculoParcela ? 'block' : 'none' }}>
              <Form.Item
                name={['condicaoCredito', 'valorSolicitado']}
                className='mb-0'
                label='Valor total desejado'
              >
                <InputMoney
                  min={useMinValue && minValue ? minValue + getSaldoDevedorTotal(selectContractsFlow) : 0}
                  max={simulationFlowMinMax?.maxValorSolicitado}
                  classNameInput='text-center'
                />
              </Form.Item>

              <SimulationMinMaxInput
                loading={loadingMaxValues}
                useMinValue={useMinValue}
                min={minValue !== undefined ? R$(minValue + getSaldoDevedorTotal(selectContractsFlow)) : false}
                max={simulationFlowMinMax?.maxValorSolicitado ? R$(simulationFlowMinMax?.maxValorSolicitado) : '-'}
              />
            </Col>

            <Col xs={24} md={12} style={{ display: metaCalculoParcela ? 'none' : 'block' }}>
              <Form.Item
                name={['condicaoCredito', 'valorParcela']}
                className='mb-0'
                label='Valor parcela desejada'
              >
                <InputMoney
                  classNameInput='text-center'
                />
              </Form.Item>

              <SimulationMinMaxInput
                loading={loadingMaxValues}
                min={R$(simulationFlowMinMax?.minValorParcela)}
                max={simulationFlowMinMax?.maxValorParcela ? R$(simulationFlowMinMax?.maxValorParcela) : '-'}
              />
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label='Quantidade de parcelas'
                className='mb-0'
                initialValue={parcelasDisponiveis[0].value}
                name={['condicaoCredito', 'quantidadeParcelas']}
              >
                <Select
                  disabled={loadingMaxValues || loadingSubmit}
                  showSearch
                  className='text-center'
                  optionFilterProp='label'
                  options={parcelasDisponiveis}
                  onSelect={
                    (parcelas) => {
                      if (!PermissionModel.canSimulateWithoutLimits(user, userPermissions)) {
                        onUpdateMinMaxByQuantidadeParcelas(
                          parcelas as number,
                          formRef.getFieldValue(['condicaoCredito', 'valorParcela']),
                          formRef.getFieldValue(['condicaoCredito', 'valorSolicitado']),
                          simulationFlowAbortController
                        )
                      }
                    }
                  }
                />
              </Form.Item>

              <SimulationMinMaxInput
                loading={loadingMaxValues}
                min={1}
                max={simulationFlowMinMax?.maxQuantidadeParcelas}
              />
            </Col>

            {(isNullish(getClientCovenantConditional(proposalType)?.valorParcela) || loadingSubmit === true) ? (
              <Col span={24}>
                <div className='text-right'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={loadingSubmit}
                    disabled={loadingMaxValues}
                    className='simulation-flow-ecpf__simulate-button'
                  >
                    Simular
                  </Button>
                </div>
              </Col>
            ) : (
              <>
                <Col xs={24} className='simulation-flow-ecpf__card-container'>
                  <SimulationCard
                    metaCalculo={MetaCalculo.ValorSolicitado}
                    title='Valor líquido a receber'
                    value={isNullish(getClientCovenantConditional(proposalType)?.valorParcela) ? 'Insira um valor' : `${format.formatBRL(getClientCovenantConditional(proposalType)?.valorCliente)}`}
                  />

                  {metaCalculoParcela && (
                    <SimulationCard
                      metaCalculo={MetaCalculo.ValorParcela}
                      title='Valor da parcela a ser paga'
                      value={isNullish(getClientCovenantConditional(proposalType)?.valorParcela) ? 'Insira um valor' : format.formatBRL(getClientCovenantConditional(proposalType)?.valorParcela)}
                    />
                  )}

                  {metaCalculoSolicitado && (
                    <SimulationCard
                      metaCalculo={MetaCalculo.ValorParcela}
                      title='Valor total desejado'
                      value={isNullish(getClientCovenantConditional(proposalType)?.valorParcela) ? 'Insira um valor' : format.formatBRL(getClientCovenantConditional(proposalType)?.valorSolicitado)}
                    />
                  )}

                </Col>
              </>
            )}
          </Row>
        </ProposalCardECPF>

        <ButtonsStepECPF
          disabled={isNullish(simulationFlow) || loadingSubmit === true}
          onSubmit={handleNextStep}
          loading={loadingValidation}
          htmlTypeSubmitButton='button'
        />
      </Form>
    </div>
  )
}

export default RefinancingSimulationFlowECPF
