import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { RenegociationContract } from '../../renegotiationInterfaces'
import DebitBalanceSVG from 'assets/reactSvgs/DebitBalanceSVG/DebitBalanceSVG'
import { R$ } from 'ecp/app/Proposals/proposalFunctions'

type Props = {
  visible: boolean
  onVisibleChange: (value: boolean) => void
  contracts: RenegociationContract[]
  debitBalanceTotal: number
}

export default function DebitBalanceModal ({ visible, onVisibleChange, contracts, debitBalanceTotal } : Props) {
  const onClose = () => {
    onVisibleChange(false)
  }

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        destroyOnClose
        onCancel={() => onClose()}
        width={400}
        centered
      >
        <div className='debit-balance-modal'>
          <h2 className='mb-5'><b>Saldo Devedor</b></h2>

          <DebitBalanceSVG className='mt-5'/>

          <div className='mt-5 debit-balance-modal__container'>
            {contracts.map((contract, index) => (
              <div className='debit-balance-modal__line' key={index}>
                <p>{`${index + 1}º contrato`}</p>
                <p>{R$(contract.saldo_devedor || 0)}</p>
              </div>
            ))}
          </div>

          <div className='debit-balance-modal__container debit-balance-modal__container--total mt-3'>
            <p className='debit-balance-modal__label-total'><b>Total</b></p>
            <p className='debit-balance-modal__value-total'><b>{R$(debitBalanceTotal)}</b></p>
          </div>

        </div>
      </Modal>
    </>
  )
}
