import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Row, Col, Typography, Dropdown, Modal, Divider, Button, Tabs, notification as antdNotification, Pagination, Skeleton, Result, message } from 'antd'
import { ArrowLeftOutlined, BellOutlined, CheckCircleOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons'

import { INotification, INotificationCore, INotificationAll } from '../Notification/types'
import { Notification } from '../'
import NotificationMoreInfo from './NotifcationMoreInfo'
import { messageErroProposalNotification, titleErroProposalNotification } from './constants'
import { useHistory } from 'react-router-dom'
import { ECP } from 'routes/ecp/constants'
import { useAuth } from 'hooks'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import NotificationRepository from 'egi/repositories/NotificationRepository'
import ReRequestButton from 'components/ReRequest/ReRequest'
import { SocketSingleton } from 'utils/socket'
import NotificationModal from 'components/NotificationModal/NotificationModal'

const { Title, Paragraph } = Typography
const { TabPane } = Tabs

interface INotificationComponentProps {
  getNotification: (page: number, filter: string, onlyNumber?: boolean) => void
  notifications: INotification[]
  setNotifications: (notifications: INotification[]) => void
  notificationsAll?: INotificationAll
  loading: boolean
  setLoading: (state: boolean) => void
  handleFilterChange: (value: string) => void
  handlePageChange: (value: number) => void
  filter: string
  getCount: Function
  notificationsLength: number | undefined
  errors?:string
}
const NotificationLoading = () => {
  return (
    <div className="p-2">
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>)
}

const NotificationEmpty = ({ filter }: { filter: string }) => {
  return (
    <Row className='notification__center-message' >
      Você não possui mensagens {filter === 'read' ? 'não lidas' : 'recebidas' }
    </Row>
  )
}

const NotificationsError = ({ error }: { error: string }) => {
  return (
    <Row className='notification__center-message' >
      <Result status='error' subTitle={error} title='Algo deu errado'/>
    </Row>
  )
}

function NotificationComponent ({
  getNotification,
  notifications,
  setNotifications,
  handleFilterChange,
  handlePageChange,
  notificationsAll,
  loading,
  setLoading,
  filter,
  getCount,
  errors,
  notificationsLength = 0

}: INotificationComponentProps) {
  const { push } = useHistory()
  const { level, _id } = useAuth()

  const [visible, setVisible] = useState(false)
  const [visibleNote, setVisibleNote] = useState(false)
  const [selectNot, setSelectNot] = useState<INotification>()
  const [notificationTab, setNotificationTab] = useState(0)
  const [newNotification, setNewNotification] = useState<boolean>(false)
  const [notificationsModal, setNotificationsModal] = useState<INotificationCore[]>([])
  const [notificationErrorMessage, setNotificationErrorMessage] = useState<string>()
  const [modalVisible, setModalVisible] = useState<{id: string, visibility: boolean}[]>([])

  const hasNotifications = notificationsLength > 0
  const bellIconFinalClass = `notification-icon ${hasNotifications ? 'notification-icon notification-icon--with-notifications' : ''}`

  const updateNotifications = () => {
    getNotification(1, filter)
    setNewNotification(false)
  }

  const handleRead = async (notificationId: string) => {
    if (notificationId) {
      try {
        await NotificationRepository.read({ notificationId })

        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  }

  const modalNotification = (notificationId: string) => async () => {
    setLoading(true)
    try {
      const response = await NotificationRepository.getPopupDetail(notificationId)
      const { title, message } = response.data?.data ?? {}

      if (!title || !message) {
        throw new Error('Não foi possível obter os detalhes da notificação.')
      }

      setNotificationsModal((prev) => [
        ...prev.filter((n) => n._id !== notificationId),
        {
          _id: notificationId,
          title,
          message,
          proposalId: '',
          createdAt: ''
        }
      ])

      setModalVisible((prev) => [
        ...prev.filter((m) => m.id !== notificationId),
        { id: notificationId, visibility: true }
      ])
    } catch (err) {
      setNotificationErrorMessage(err instanceof Error ? err.message : 'Erro ao carregar os detalhes da notificação.')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    const loadWarnings = async () => {
      try {
        const response = await NotificationRepository.getPopups()
        const { ids } = response.data?.data || {}

        if (ids) {
          ids.forEach((id) => { if (id) modalNotification(id)() }
          )
        }
      } catch (err) {
        setNotificationErrorMessage(err as string)
        console.error(err)
      }
    }

    loadWarnings()
  }, [])

  useEffect(() => {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.on('new notification', () => {
        setNewNotification(true)
        getCount()
      })

      socket.io.on('set read', () => {
        getCount()
      })
    }

    return () => {
      if (socket.io) {
        socket.io.off('new notification')
        socket.io.off('set read')
      }
    }
  }, [notifications])

  useEffect(() => {
    function openProposalEcpNotification ({ message, proposalId, title }: Omit<INotificationCore, 'createdAt' | '_id'>) {
      antdNotification.open({
        description:
          <div className='ant-notification-notice__description'>
            {message ?? messageErroProposalNotification}
            <label className='ant-notification-notice__details'>
              Clique para mais informações.
            </label>
          </div>,
        message:
          <>
            <CheckCircleOutlined />

            <label className='ant-notification-notice__title'>
              ECP - {title ?? titleErroProposalNotification}
            </label>
          </>,
        onClick: () => push('/auth' + ECP.PROPOSAL_DETAILS.path.replace(':proposalId', proposalId ?? '')),
        className: 'proposal-notification',
        duration: 5
      })
    }

    if (level === 'client') {
      const socket = new SocketSingleton()
      if (socket.io) {
        socket.io.emit('join-notification', _id)

        socket.io.on('contractSuccess', ({ message, proposalId, title }: INotificationCore) =>
          openProposalEcpNotification({ message, proposalId, title })
        )

        socket.io.on('solicitationSuccess', ({ message, proposalId, title }: INotificationCore) =>
          openProposalEcpNotification({ message, proposalId, title })
        )
      }
    }
  }, [])

  useEffect(() => {
    if (visible) {
      setVisibleNote(false)
    }
  }, [visible])

  useEffect(() => {
    setNotifications(notificationsAll?.notifications?.docs ?? [])
  }, [notificationsAll])

  const menu = () => {
    return (
      <div className="content notification__bx-shadow ">
        {loading ? (
          <Row justify={'center'} className="p-3">
            <LoadingOutlined />
          </Row>
        ) : (!notifications || notifications.length === 0) ? (
          <Row justify={'center'} className="content">
            <Paragraph>Você não possui novas notificações</Paragraph>
          </Row>
        ) : (
          <Fragment>
            {notifications.map((notification: INotification, index) => {
              if (index < 3) {
                return (<>
                  <Notification
                    key={notification.notificationId}
                    notification={notification}
                    handleRead={(id: string) => {
                      handleRead(id)
                    }}
                    onClick={() => {
                      setNotificationTab(1)
                    }}
                    setVisible={setVisible}
                    setSelectNot={setSelectNot}
                    select={selectNot}
                  />

                </>
                )
              }

              return <></>
            })}

          </Fragment>
        )}

        <Row className="row my-3">
          <Button
            className="button-text mx-1 bold"
            block
            type="primary"
            onClick={() => {
              setVisible(true)
            }}
          >
            {notifications.length >= 3
              ? 'Ver mais'
              : 'Ver histórico'
            }
          </Button>
        </Row>
      </div>
    )
  }

  const goBackButton =
    <div className='notification__goBack-button' style={{ height: '100%', width: '30px' }}>
      <ArrowLeftOutlined
        className="ant-page-header-back-button pr-2"
        onClick={() => {
          setNotificationTab(0)
          getNotification(1, filter)
          setNewNotification(false)
        }}
        style={{ margin: '1em 0' }}
      />
    </div>

  const handleReadAll = async () => {
    try {
      await NotificationRepository.read({ all: true })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    } finally {
      getNotification(1, filter)
    }
  }

  const toggleNotificationModal = (visible: boolean, notificationId: string) => {
    try {
      NotificationRepository.readPopup({ popupId: notificationId, popup: true })
    } catch (err) {
      message.error(err)
      console.error(err)
    } finally {
      setModalVisible((prev) =>
        prev.map((modal) => (modal.id === notificationId ? { ...modal, visibility: visible } : modal))
      )
    }
  }

  return (
    <Fragment>
      {notificationsModal.map((notification, index) => (
        <NotificationModal
          key={index}
          title={notification?.title}
          message={notification?.message}
          isVisible={modalVisible.find(modal => modal.id === notification._id)?.visibility ?? false}
          setIsVisible={(visible: boolean) => toggleNotificationModal(visible, notification._id)}
          errorMessage={notificationErrorMessage ?? ''}
          isLoading={loading}
        />
      ))}

      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomCenter"
        onVisibleChange={() => setVisibleNote(!visibleNote)}
        visible={visibleNote}
      >
        <section className="notification__relative-icon">
          <BellOutlined
            data-count={notificationsLength > 8 ? '9+' : notificationsLength }
            className={bellIconFinalClass}
            onClick={() => {
              updateNotifications()
            }}
          />
        </section>
      </Dropdown>

      <Modal
        className='notification__modal'
        title={<div className="notification__modal-header">Notificações
          <div className="notification__new">
            <ReRequestButton onClick={() => {
              updateNotifications()
            }

            } />
            {newNotification && <span className="notification__new-item">
              • Nova atualização •
            </span>}
          </div>
        </div>}
        visible={visible}
        closable
        destroyOnClose
        onCancel={() => {
          setVisible(false)
          setSelectNot(undefined)
          setNotificationTab(0)
          setNewNotification(false)
          handleFilterChange('read')
        }}
        okButtonProps={{
          style: { display: 'none' }
        }}
        cancelButtonProps={{
          style: { display: 'none' }
        }}
        style={{ padding: 0, top: 'min(100px, 10vh)' }}
        footer={[
          <Row key={0}>
            <Col>
              <Button
                block
                icon={<MailOutlined />}
                type="primary"
                onClick={() => {
                  handleReadAll()
                }}
              >
                Marcar todas com lida
              </Button>
            </Col>
          </Row>
        ]}
      >

        <div className='notification__expanded-modal'>

          <div className={`notification__nav ${notificationTab !== 0 ? 'notification__mobile-hide' : ''}`}>
            <>
              <div className='notification__header-tabs'>
                <Tabs
                  className='notification__tabs'
                  defaultActiveKey="read"
                  type="card"
                  onTabClick={(key: string) => {
                    handleFilterChange(key)
                  }}
                >
                  <TabPane tab="Não lidas" key="read" />
                  <TabPane tab="Recebidas" key="received" />
                </Tabs>
              </div>
            </>

            <div className='notification__scroll'>
              {loading && <NotificationLoading />}
              {errors && <NotificationsError error={errors}/>}
              {!loading && !errors && <>
                {notifications.length ? notifications.map((notification: INotification) => (
                  <Notification

                    key={notification._id}
                    notification={notification}
                    handleRead={(id: string) => handleRead(id)}
                    onClick={() => {
                      setNotificationTab(1)
                    }
                    }
                    setVisible={setVisible}
                    setSelectNot={setSelectNot}
                    select={selectNot}
                  />

                )) : (
                  < NotificationEmpty filter={filter} />
                )}</>}

              <div className="notification__pagination">
                <Pagination total={notificationsAll?.notifications?.totalDocs ?? 0} size="small" onChange={page => handlePageChange(page)} showSizeChanger={false}/>
              </div>

            </div>

          </div>

          <Divider
            className='notification__divider'
            type="vertical"
          />
          <div className={`notification__content ${notificationTab !== 1 ? 'mobile-hide' : ''}`}>
            {goBackButton}
            <Row className="h-100 w-100">
              {selectNot ? (
                <div className="w-100">
                  <Row align="middle">
                    <Title className='notification__title mb-0' level={2}>{selectNot.title}</Title>
                  </Row>

                  {selectNot.redirectTo && (
                    <NotificationMoreInfo
                      info={selectNot}
                      setVisible={setVisible}
                    />
                  )}
                  <Divider />
                  <div className='notification__scroll-title'>
                    <div className="paragraph">
                      <Paragraph>{selectNot.message ? <StringToHtml value={selectNot.message} /> : 'Nenhuma mensagem'}</Paragraph>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='notification__center-message'>
                    Nenhuma mensagem selecionada
                </div>
              )}
            </Row>
          </div>

        </div>
      </Modal>
    </Fragment>
  )
}

export default NotificationComponent
