import { IDynamicProposal, IDynamicProposalStep, _proposalDepartments } from 'ecp/app/Proposals/proposalInterfaces'
import { TipoConsultaEcpfEnum } from 'ecpf/models/ClientEcpfModel'
import { Department } from 'store/modules/proposal/types'
import { IReduxProposalFlow } from 'store/modules/proposalFlow/actions'
import { IPortabilitySimulationDataECPF } from './components/PortabilitySimulationFlowECPF/PortabilitySimulationFlowECPF'

export enum ProposalType {
  new = 'new-contract',
  refinancing = 'refinancing',
  renegotiation = 'renegotiation',
  portability = 'portability'
}

export enum ProposalFlowStep {
  customerData = 'customerData',
  simulation = 'simulation',
  simulationResume = 'simulationResume',
  extraCustomerData = 'extraCustomerData',
  tokenValidation = 'tokenValidation',
  portabilitySimulation = 'portabilitySimulation',
  portabilitySimulationResume = 'portabilitySimulationResume',
  refinancingSimulation = 'refinancingSimulation',
  renegotiationSimulation = 'renegotiationSimulation'
}

export enum ProposalECPFModality {
  novoContrato = '000060',
  refinanciamento = '000061',
  portabilidade = '000062',
  renegociacao = '000063'
}

interface IReferencia {
  ddd?: string
  nome?: string
  telefone?: string
  tipo?: string
  tipoTelefone?: string
}

export interface ISimulationECPFBody {
  tipoOperacao?: string
  convenios?: Array<{ codigo: string, descricao: string }>
  origens?: {
    codigoMatriz?: string
    codigoRegional?: string
    codigoPromotor?: string
    codigoEmpregador?: string
    codigoOrgao?: string
  }
  cliente?: {
    nome?: string
    codigo?: string
    documento?: string
    numeroMatricula?: string
    dataNascimento?: string
    dataAdmissao?: string
    valorRenda?: number
    pis?: string
    cnpj?: string
  }
  condicaoCredito?: {
    valorSolicitado?: number
    valorParcela?: number
    taxaCliente?: number
    quantidadeParcelas?: number
    metaCalculo?: MetaCalculo | number
    codigoConvenio?: string
    meioLiberacaoCliente?: string
    valorFGTS?: number

    // This props is only used to change the isentar inside the simulationResponse
    // to more details, check ProposalSimulationFuncaoModel.simulationReponseFactory
    seguro?: boolean
    useMinValue?: boolean
  }
  refinanciamento?: {
    operacoes?: Array<{
      numeroOperacao?: string
    }>
  }
  portabilidade?: {
    operacoes?: Array<portabildaOperacaoECPF>
  }
  compras?: {
    operacoes?: Array<{
      saldo?: number
    }>
  }
  naoCalcularComissao?: string
  despesas?: { despesas: Array<IProposalDespesasItemECPF> }
  saldoDevedor?: number
}

export interface portabildaOperacaoECPF {
  saldo?: number
  dataBaseContrato?: string
  tipoQuitacao?: string
}

export interface IDadoTelefonesECPF {
  ddd?: string
  tipo?: string
  numeroTelefone?: string
  numero?: string
}

export enum EscolaridadeEnum {
  NaoDefinido = 'NaoDefinido',
  PrimeiroGrau = 'PrimeiroGrau',
  SegundoGrau = 'SegundoGrau',
  Superior = 'Superior',
  SemEscolaridade = 'SemEscolaridade',
  PosGraduacao = 'PosGraduacao',
  MestradoDoutorado = 'MestradoDoutorado'
}

export enum SexoDadoCadastralECPF {
  feminino = 'Feminino',
  masculino = 'Masculino'
}

export interface IDadoCadastral {
  tipo?: TipoConsultaEcpfEnum | 'Cliente' | 'Proponente'
  tipoCliente?: TipoConsultaEcpfEnum
  codigo?: string
  tipoPessoa?: string
  documento?: string
  nome?: string
  nomeMae?: string
  nomePai?: string
  email?: string
  nacionalidade?: string
  tipoDocumento?: string
  numeroDocumento?: string
  ufDocumento?: string
  dataEmissaoDocumento?: string
  orgaoEmissorDocumento?: string
  estadoCivil?: string
  quantidadeDependentes?: number
  dataNascimento?: string
  sexo?: SexoDadoCadastralECPF
  valorRenda?: number
  valorRendaOutros?: number
  escolaridade?: string
  naturalidade?: string
  naturalidadeUF?: string
  pep?: boolean
  dataContracheque?: string
  validarUFOM?: boolean
  dadoEndereco?: DadoEndereco
  dadoProfissional?: DadoProfissional
  dadosBancarios?: { itens?: DadoBancario[] }
  bancario?: { itens?: DadoBancario[] }
  dadoBeneficio?: DadoBeneficio
  telefones?: { itens?: IDadoTelefonesECPF[] }
  telefone?: { itens?: IDadoTelefonesECPF[] }
  referencia?: { itens?: IReferencia[] }
  conjuge?: {
    ddd?: string
    nome?: string
    telefone?: string
  }
}

interface DadoEndereco {
  tipoCorrespondencia?: string
  tipoEnderecoCorrespondencia?: string
  tipoResidencia?: string
  enderecos?: { itens?: IEnderecoItem[] }
  endereco?: { itens?: IEnderecoItem[] }
}

export interface IEnderecoItem {
  tipo?: string
  cep?: string
  logradouro?: string
  numero?: string
  complemento?: string
  bairro?: string
  cidade?: string
  uf?: string
}

interface DadoBancario {
  compensacao?: string
  banco?: string
  agencia?: string
  agenciaDigito?: string
  tipoConta?: string
  conta?: string
  contaDigito?: string
  dataAbertura?: string
}

interface DadoBeneficio {
  recebeBeneficioCartao?: boolean
  beneficioUF?: string
}

interface CompraOperacao {
  tipoQuitacao?: string
  bancoOriginador?: string
  contratoPortado?: string
  dataBaseContrato?: string
  valorPMT?: number
  saldo?: number
  tipoConta?: string
  numeroBanco?: string
  dataVencimento?: string
}

interface Compras {
  operacoes?: CompraOperacao[]
}

interface IProposalCondicaoCredito {
  codigoConvenio?: string
  codigoProduto?: string
  quantidadeParcelas?: number
  valorSolicitado?: number
  valorPrincipal?: number
  valorBruto?: number
  valorParcela?: number
  valorIOF?: number
  valorLiquido?: number
  valorCliente?: number
  dataPrimeiroVencimento?: string
  dataUltimoVencimento?: string
  taxaCETAM?: number
  taxaCETAA?: number
  taxaAPAM?: number
  taxaAPAA?: number
  taxaCLAM?: number
  taxaCLAA?: number
  taxaNominalAM?: number
  taxaNominalAA?: number
  metaCalculo?: MetaCalculo | number
  meioLiberacaoCliente?: string
  despesas?: { itens: Array<IProposalDespesasItemECPF> }
}

interface Origens {
  codigoMatriz?: string
  codigoRegional?: string
  codigoPromotor?: string
  codigoEmpregador?: string
  codigoOrgao?: string
}

interface DadoProfissional {
  dataAdmissao?: string
  numeroMatricula?: string
  empresaCNPJ?: string
  empresaDocumento?: string
  empresaNome?: string
  cargo?: string
}

export interface IProposalSubmissionDataBody {
  tipoCliente?: string
  codigoCliente?: string
  documento?: string
  tipoOperacao?: string
  origens?: Origens
  condicaoCredito?: IProposalCondicaoCredito
  compras?: Compras
  dadoCadastral?: IDadoCadastral
  despesas?: ICondicao['despesas']
  portabilidade?: { operacoes: Array<portabildaOperacaoECPF> }
  refinanciamento?: {
    operacoes: Array<{
      numeroOperacao: string
    }>
  }
  saldoDevedor?: number
}

export enum CodigoDespesa {
  valorSeguro = 2,
  taxaCadastro = 1
}

export type ICondicao = {
  convenio: {
    codigoConvenio: string
    descricaoConvenio: string
    observacao: string
  }
  produto: {
    codigoProduto: string
    descricaoProduto: string
  }
  despesas: { itens: Array<IProposalDespesasItemECPF> }
  quantidadeParcelas: number
  valorSolicitado: number
  valorPrincipal: number
  valorParcela: number
  valorBruto: number
  valorIOF: number
  valorLiquido: number
  valorCliente: number
  dataPrimeiroVencimento: string
  dataUltimoVencimento: string
  taxaCETAM: number
  taxaCETAA: number
  taxaAPAM: number
  taxaAPAA: number
  taxaCLAM: number
  taxaCLAA: number
  taxaNominalAM: number
  taxaNominalAA: number
  metaCalculo: MetaCalculo | number
}
export interface ISimulationECPFResponse {
  condicoes: Array<ICondicao>
}

export enum MetaCalculo {
  ValorSolicitado = 'ValorSolicitado',
  ValorParcela = 'ValorParcela'
}

interface IProposalEsteiraECPF {
  situacaoProposta: string
  dataHoraUltimaAtividade: string
  numeroAtividadeAtual: string
  descricaoAtividadeAtual: string
}

interface IProposalConvenioECPF {
  codigo: string
  descricao: string
  dataCorte: string
}

interface IProposalProdutoECPF {
  codigo: string
  descricao: string
  tipoProduto: string
}

interface ITabelaFinanciamento {
  codigo: string | null
}

interface IProposalFgtsECPF {
  valor: string
  valorGarantia: string
}

export enum OrigemItemECPF {
  empresa = 'Origem0',
  matriz = 'Origem1',
  regional = 'Origem2',
  promotor = 'Origem3',
  empregador = 'Origem4',
  orgao = 'Origem5',
  digitador = 'Origem6',
  origem3O = 'Origem30'
}

export interface IProposalOrigemItemECPF {
  tipo: OrigemItemECPF
  codigo: string
  descricao: string
}

interface IProposalOrigemECPF {
  itens: IProposalOrigemItemECPF[]
}

interface IParcelasItem {
  numero: number
  valor: number
  dataVencimento: string
}

interface IParcelas {
  itens: IParcelasItem[]
}

interface ITaxas {
  cetam: number
  cetaa: number
  apam: number
  apaa: number
  clam: number
  claa: number
  nominalAM: number
  nominalAA: number
}

export interface ICondicaoCredito {
  dataBase: string
  valorSolicitado: number
  valorPrincipal: number
  valorBruto: number
  valorIOF: number
  valorLiquido: number
  valorCliente: number
  quantidadeParcelas: number
  valorParcela: number
  dataPrimeiroVencimento: string
  dataUltimoVencimento: string
  meta: string
  parcelas: IParcelas
  taxas: ITaxas
}

export interface IProposalLiberacaoItemECPF {
  numero: number
  tipoBeneficiario: string
  meioLiberacao: string
  tipoDocumento: string
  tipoConta: string
  documento: string
  nomeFavorecido: string
  valor: number
  codigoBanco: string
  numeroAgencia: string
  digitoAgencia: string
  numeroConta: string
  digitoConta: string
}

interface IProposalLiberacaoECPF {
  itens: IProposalLiberacaoItemECPF[]
}

interface IEndereco {
  itens: IEnderecoItem[]
}

interface IProposalClientTelefone {
  itens: IDadoTelefonesECPF[]
}

interface IProposalClienteECPF {
  documento: string
  nomeCliente: string
  tipoDocumento: string
  numeroDocumento: string
  estadoCivil: string
  dataNascimento: string
  escolaridade: string | null
  numeroMatricula: string
  pis: string | null
  endereco: IEndereco
  telefone: IProposalClientTelefone
}

export interface IProposalDespesasItemECPF {
  codigo: string
  codigoTipo: number
  numeroItem: number
  codigoGrupo: CodigoDespesa
  valor?: number
  alteraDefault?: boolean
  valorMinimo?: number
  valorMaximo?: number
  isentar: boolean
  despesaFinanciada?: boolean
}

interface IProposalDespesasECPF {
  itens: IProposalDespesasItemECPF[]
}

interface IProposalEconsigECPF {
  codigoVerba: string | null
  codigoServico: string | null
}

export interface IProposalDetailsResponse {
  idProposta: string
  numeroProposta: string
  numeroOperacao: string
  tipoOperacao: string
  dataCadastro: string
  loginDigitador: string
  codigoAverbacao: string | null
  situacaoPropostaEsteira: string
  tipoFormalizacao: string | null
  esteira: IProposalEsteiraECPF
  convenio: IProposalConvenioECPF
  produto: IProposalProdutoECPF
  tabelaFinanciamento: ITabelaFinanciamento
  fgts: IProposalFgtsECPF
  origem: IProposalOrigemECPF
  condicaoCredito: ICondicaoCredito
  liberacao: IProposalLiberacaoECPF
  cliente: IProposalClienteECPF
  despesas: IProposalDespesasECPF
  econsig: IProposalEconsigECPF
}

export interface IProposalDepartmentsECPF {
  esteira: IDynamicProposal.Department
}

export enum DynamicProposalStatusEnum {
  starting = 'starting',
  approved = 'approved',
  remaking = 'remaking',
  analysis = 'analysis',
  reproved = 'reproved',
  inProgress = 'inProgress',
  canceled = 'canceled',
  error = 'error'
}

export interface IFuncaoDynamicProposalDepartment {
  name: string
  slug: string
  steps: IDynamicProposalStep[]
  icon?: string
  _id: string
}

export interface ILinedataProposal{
  _id: string
  status: string
  statusLabel: string
  translatedStatusLabel: string
  createdAt: string
  departmentSlugs: string[]
  departments: {
    [key in _proposalDepartments]: Department
  }
  lastDepartment: _proposalDepartments
  lastDepartmentId: string
  originCreation: string
  productId: string
  productSlug: string
  sensediaId: string
  systemType: string
  updatedAt: string
}

export interface IProposalLineData {
  departments: IFuncaoDynamicProposalDepartment[]
  proposal: ILinedataProposal
  error?: {
    code: string
    data: { proposalId: string }
    proposalId: string
    message: string
  }
}

export enum duePaymentStatusEnum {
  naoDefinido = 'NaoDefinido',
  pago = 'Pago',
  atrasado = 'Atrasado',
  pendente = 'Pendente'
}

export interface IProposalSubmissionBody {
  proposal: IProposalSubmissionDataBody
  conditional: ICondicao
  proposalType: ProposalType
  isNewClient: boolean
  client?: IReduxProposalFlow['client']
  portabilitySimulationFlowData?: IPortabilitySimulationDataECPF
  selectContractsFlow?: IReduxProposalFlow['selectContractsFlow']
}
