import { AxiosRequestConfig } from 'axios'
import { INotification } from 'components/Notification/types'
import { _userLevel } from 'ecp/models/UsersModel'
import { IPaginate, IResponseBase, Repository } from 'ecp/repositories/Repository'

import api from 'services/api'

class NotificationRepository extends Repository<INotification> {
  clientList (clientId: string, config?: AxiosRequestConfig) {
    return Repository.handle<IPaginate<INotification>>(() =>
      this.api.get(`${this.path}/${clientId}`, config)
    )
  }

  count (config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/count}`, config)
    )
  }

  send (body: { title: string, message: string, level: _userLevel, areaId: string }, config?: AxiosRequestConfig) {
    return Repository.handle<{ notification: INotification[] }>(() =>
      this.api.post(this.path, body, config)
    )
  }

  read (body: { notificationId?: string, all?: boolean }, config?: AxiosRequestConfig) {
    return Repository.handle<{ notification: INotification[] }>(() =>
      this.api.patch(`${this.path}/read`, body, config)
    )
  }

  getPopups (config?: AxiosRequestConfig) {
    return Repository.handle<{ ids: string[] }>(() =>
      this.api.get(`${this.path}/f?popup=true`, config)
    )
  }

  getPopupDetail (popupId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{title: string, message: string}>(() =>
      this.api.get(`/popup/user/${popupId}`, config)
    )
  }

  readPopup (body: { popupId?: string, popup?: boolean }, config?: AxiosRequestConfig) {
    return Repository.handle<{ notification: INotification[] }>(() =>
      this.api.patch(`${this.path}/read`, body, config)
    )
  }
}
export default new NotificationRepository({ api, path: '/notification' })
