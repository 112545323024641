import { AxiosRequestConfig } from 'axios'
import { swalErrorECPF } from 'components/SwalErrorECPF/SwalErrorECPF'
import { ProposalSimulationFuncaoModel } from 'ecpf/models/ProposalFuncaoModel'
import { ProposalService } from 'ecpf/services/proposalService'
import { SimulationService } from 'ecpf/services/simulationService'
import { useState } from 'react'
import { ISimulationECPFBody } from '../ProposalECPFInterfaces'
import { useProposalContextECPF } from './useProposalContextECPF'
import { useProposalFlow } from 'ecpf/hooks/useProposalFlow'

function useSimulationECPF () {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { proposalType } = useProposalContextECPF()
  const { client } = useProposalFlow()

  const onSimulation = async (simulation: ISimulationECPFBody, abortController: AbortController) => {
    setLoadingSubmit(true)
    const proposalService = new ProposalService()

    try {
      const credito = await proposalService.onSubmitSimulation(simulation, { signal: abortController.signal })
      const creditoFactory = ProposalSimulationFuncaoModel.simulationReponseFactory({
        conditional: credito,
        simulation,
        client,
        proposalType
      })

      return creditoFactory
    } catch (err) {
      console.log({ err })
      throw err
    } finally {
      setLoadingSubmit(false)
    }
  }

  return { onSimulation, loadingSubmit, setLoadingSubmit }
}

export default useSimulationECPF

export function useValidateSimulationMaxSolicitation () {
  const [loadingValidation, setLoadingValidation] = useState(false)

  const onValidateMaxSolicitation = async (config: AxiosRequestConfig, onFinish: () => void) => {
    setLoadingValidation(true)
    const simulationService = new SimulationService()

    try {
      const result = await simulationService.validateMaxSolicitation(config)
      onFinish()
      return result
    } catch (error) {
      swalErrorECPF({ error })
    } finally {
      setLoadingValidation(false)
    }
  }

  return { onValidateMaxSolicitation, loadingValidation }
}
