import { Button } from 'antd'
import ProposalModel from 'ecpf/models/ProposalFuncaoModel'
import React from 'react'
import { useProposalContextECPF } from '../../hooks/useProposalContextECPF'

function ButtonsStepECPF ({ onSubmit, onLastStep, disabled, loading, submitButtonText, htmlTypeSubmitButton = 'submit' }: {
  onSubmit?: () => void
  onLastStep?: () => void
  disabled?: boolean
  loading?: boolean
  submitButtonText?: string
  htmlTypeSubmitButton?: 'submit' | 'button' | 'reset'
}) {
  const { currentStep, onPrevStep } = useProposalContextECPF()
  const canShowBackButton = ProposalModel.canShowStepBackButton(currentStep)

  return (
    <aside
      className={`buttons-step ${currentStep === 0 && 'buttons-step--first'}`}>
      {canShowBackButton && (
        <Button
          type='default'
          loading={loading}
          onClick={() => {
            onPrevStep()
            onLastStep?.()
          }}
          className='buttons-step__button-back'
        >
          Voltar
        </Button>
      )}

      <Button
        type='primary'
        htmlType={htmlTypeSubmitButton}
        loading={loading}
        disabled={disabled}
        onClick={() => onSubmit && onSubmit()}
        className='pr-5 pl-5'
      >
        {submitButtonText || 'Continuar'}
      </Button>
    </aside>
  )
}

export default ButtonsStepECPF
