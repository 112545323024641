import api from 'services/api'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import { AxiosRequestConfig } from 'axios'
import { IChatOtherPerson } from 'store/modules/chat/types'
import { ISelect } from 'egi/types'

export interface IChats {
  chatName?: string
  chatNumber?: number
  chatImage?: string
  createdAt: string
  helperId: string
  notReadMessages: number
  otherPersonTalking: IChatOtherPerson
  closeReason?: string
  lastMessage?: string
  private: boolean
  reason: string
  requesterId: string
  _id: string
}

export enum _chatConfigType {
  report = 'report',
  resolution = 'resolution'
}

class ChatRepository extends Repository<IChats> {
  async helpdesk (data: FormData, config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/helpdesk`, data, config)
    )
  }

  async getConversations (config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/`, config)
    )
  }

  async getUser (ticketId: string, config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/user/${ticketId}`, config)
    )
  }

  async sendMessages (ticketId : string, data: FormData, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/send/${ticketId}`, data, config)
    )
  }

  async getMessages (ticketId : string, config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/info/${ticketId}`, config)
    )
  }

  async reports (config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/reports`, config)
    )
  }

  async closeChat (chatId: string, data: {comments: string}, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/close/${chatId}`, data, config)
    )
  }

  async pending (config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.get(`${this.path}/pending`, config)

    )
  }

  async fetchReasons (configType: _chatConfigType, config?: AxiosRequestConfig) {
    return Repository.handle<{ reason: Array<ISelect> }>(() =>
      this.api.get(`${this.path}/reasons/line/list/${configType}`, config)
    )
  }

  async fetchMaxFileSize (config?: AxiosRequestConfig) {
    return Repository.handle<{ maxFileSize: number }>(() =>
      this.api.get(`${this.path}/max-file-size`, config)
    )
  }
}

export default new ChatRepository({ api, path: '/chats' })
