import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { IConfigurationECPFBody, IConfigurationECPFResponse } from 'ecpf/services/configurationService'
import api from 'services/api'

export interface IConfigurationMaxSolicitationResponse {
  _id?: string
  createdAt?: string
  updatedAt?: string
  productId?: string
  maxSolicitation?: number
  userId?: string
  ecpFuncaoConfigurationsMaxSolicitationCode?: number
}

export interface IMaxSolicitationConfigurationForm {
  maxSolicitation: number
  productId: string
}

export interface IConfigurationRefinancingResponse {
  configuration: {
    _id?: string
    createdAt?: string
    updatedAt?: string
    minValue?: number
    useMinValue?: boolean
  }
}

export interface IConfigurationRefinancingSend {
  useMinValue: boolean
}

export interface IRefinancingLog {
  _id: string
  minValue: number
  useMinValue: boolean
  createdAt?: string
  updatedAt?: string
}

export interface IRefinancingLogsResponse {
  logs: {
    docs: IRefinancingLog[]
    totalDocs: number
    limit: number
    totalPages: number
    page: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    prevPage: number | null
    nextPage: number | null
  }
}

class ConfigurationECPFRepository extends Repository<{}> {
  async lastConfigurationWhatsapp (config?: AxiosRequestConfig) {
    return Repository.handle<{ configuration: IConfigurationECPFResponse }>(() =>
      this.api.get(`${this.path}/whatsapp`, config)
    )
  }

  async createWhatsappConfiguration (body: IConfigurationECPFBody) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/whatsapp`, body)
    )
  }

  async getMaxSolicitation (productId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ configurationMaxSolicitation: IConfigurationMaxSolicitationResponse }>(() =>
      this.api.get(`${this.path}/max-solicitation/product/${productId}`, config)
    )
  }

  async updateMaxSolicitation (body: IMaxSolicitationConfigurationForm) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/max-solicitation`, body)
    )
  }

  async getMinimumRefinancingValue (config?: AxiosRequestConfig) {
    return Repository.handle<IConfigurationRefinancingResponse>(() =>
      this.api.get(`${this.path}/refinancing`, config)
    )
  }

  async postMinimumRefinancingValue (value?: IConfigurationRefinancingSend, config?: AxiosRequestConfig) {
    return Repository.handle<IConfigurationRefinancingResponse>(() =>
      this.api.post(`${this.path}/refinancing`, value, config)
    )
  }

  async getListRefinancingLogs (config?: AxiosRequestConfig) {
    return Repository.handle<IRefinancingLogsResponse>(() =>
      this.api.get(`${this.path}/refinancing/list`, config)
    )
  }
}

export default new ConfigurationECPFRepository({ api, path: '/ecp-funcao/configurations' })
