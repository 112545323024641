import React from 'react'

function ProposalCardECPF ({ children, className }: { children: React.ReactNode, className?: string }) {
  return (
    <section className={`proposal-card ${className}`}>
      {children}
    </section>
  )
}

export default ProposalCardECPF
