import { AxiosRequestConfig } from 'axios'
import ConfigurationECPFRepository, { IMaxSolicitationConfigurationForm } from 'ecpf/repositories/ConfigurationECPFRepository'
import format from 'utils/format'

export interface IConfigurationECPFBody {
  seguroTooltipMessage: string
  whatsappComercialNumber: string
}

export interface IConfigurationECPFResponse extends IConfigurationECPFBody {
  _id: string
  createdAt: string
}

export class ConfigurationNotFound extends Error {
  name = 'CONFIGURATION_NOT_FOUND'
  message = 'Configuração não encontrada.'
  code = 404
}

export class ConfigurationService {
  async lastWhatsappConfiguration (config?: AxiosRequestConfig) {
    const response = await ConfigurationECPFRepository.lastConfigurationWhatsapp(config)

    const { configuration } = response.data.data ?? {}
    if (!configuration) throw new ConfigurationNotFound()

    return configuration
  }

  async createWhatsappConfiguration (body: IConfigurationECPFBody) {
    const formatedBody = {
      seguroTooltipMessage: body.seguroTooltipMessage,
      whatsappComercialNumber: `55${format.onlyDigits(body.whatsappComercialNumber)}`
    }

    return ConfigurationECPFRepository.createWhatsappConfiguration(formatedBody)
  }

  async getMaxSolicitation (productId: string) {
    return ConfigurationECPFRepository.getMaxSolicitation(productId)
  }

  async updateMaxSolicitation (body: IMaxSolicitationConfigurationForm) {
    return ConfigurationECPFRepository.updateMaxSolicitation(body)
  }
}
