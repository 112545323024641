import React, { useRef, useState, MutableRefObject, useEffect } from 'react'
import { Content } from 'antd/lib/layout/layout'
import { Alert, Button, Checkbox, Col, Form, Row, Select, Tooltip } from 'antd'
import { Input } from 'components'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import translate from 'utils/translate'
import { useDispatch } from 'react-redux'
import { useAuth, useResources } from 'hooks'
import { resourcesGetAreas } from 'store/modules/resources/actions'
import { _userLevel } from 'ecp/models/UsersModel'
import { INotificationError } from '../internalNotificationInterfaces'
import NotificationRepository from 'egi/repositories/NotificationRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

function PopUpCheckbox ({ onChange, ...rest }: {onChange: (e: CheckboxChangeEvent) => void}) {
  return (
    <>
      <Checkbox onChange={onChange} {...rest}>Disparar pop-up?</Checkbox>
      <Tooltip overlay={<>Escolha se deseja que a plataforma exiba a mensagem imediatamente aos usuários.</>}>
        <QuestionCircleOutlined style={{ cursor: 'help' }} />
      </Tooltip>
    </>
  )
}

export default function InternalNotification () {
  const formRef: MutableRefObject<any> = useRef(null)
  const user = useAuth()
  const resources = useResources()
  const dispatch = useDispatch()

  const [richText, setRichText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<INotificationError>({})
  const [level, setLevel] = useState<string>('')
  const [showLevel, setShowLevel] = useState<boolean>(false)

  const levels: Array<_userLevel> = ['master', 'commission', 'pricing', 'commercial', 'administrator', 'consultant', 'backoffice']
  const myLevel = levels.indexOf(user.level || '')

  const resetFields = () => {
    formRef?.current.resetFields()
    setRichText('')
    setShowLevel(false)
  }

  const onSubmit = async (values: { title: string; level: string; areaId?: string; popup: boolean }) => {
    const data: any = {
      title: values.title,
      level: values.level,
      clientId: user._id,
      popup: values.popup
    }

    if (richText) data.message = richText
    if (values.areaId) data.areaId = values.areaId

    setLoading(true)
    try {
      const response = await NotificationRepository.send(data)
      swal.basic({ title: 'Sucesso', text: response.data?.message, icon: 'success' })
      setErrors({})
      setLevel('')
      resetFields()
    } catch (err) {
      if (err.data?.invalid) {
        let errors: INotificationError = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setLoading(false)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (resources.areas.length < 1) dispatch(resourcesGetAreas())
    resetFields()
  }, [])

  return (
    <Content>
      <Alert
        showIcon
        type='warning'
        className='mb-3'
        message={
          <span>
            Esta notificação será enviada <strong>apenas para os usuários que possuem o produto</strong> relacionado à mensagem. Certifique-se de que o conteúdo
            seja relevante para esse público.
          </span>
        }
      />

      <div className="card-style p-3 pt-3">
        <Form
          onFinish={onSubmit}
          layout={'vertical'}
          ref={formRef}
        >
          <Row gutter={[15, 15]}>
            <Col lg={16} sm={24} xs={24}>
              <Form.Item
                name='title'
                label={<label>Título:</label>}
                help={errors.title && (errors.title)}
                validateStatus={errors.title && ('error')}
              >
                <Input
                  placeholder={'Digite o título'}
                ></Input>
              </Form.Item>
            </Col>

            {!showLevel && (
              <Col lg={8} sm={24} xs={24}>
                <Form.Item
                  name='level'
                  label={<label>Nível:</label>}
                  help={errors.level && (errors.level)}
                  validateStatus={errors.level && ('error')}
                >
                  <Select
                    placeholder={'Selecione o nível'}
                    onChange={(level: string) => setLevel(level)}
                  >
                    {levels.map((level:string, index) => {
                      if (index >= myLevel) {
                        return (
                          <Select.Option
                            key={index}
                            value={level}
                          >
                            {translate.level(level)}
                          </Select.Option>
                        )
                      }
                      return <></>
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {level === 'backoffice' && (
              <Col lg={8} sm={24} xs={24}>
                <Form.Item
                  name='areaId'
                  label={<label>Departamento que receberá a notificação:</label>}
                >
                  <Select placeholder='Selecione o departamento'>
                    {resources.areas.map((area: { label: string, value: string }) => (
                      <Select.Option
                        key={area.label}
                        value={area.value}
                      >
                        {area.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col lg={24} sm={24} xs={24}>
              <Form.Item
                name='message'
                label={<label>Mensagem:</label>}
                help={errors.message && (errors.message)}
                validateStatus={errors.message && ('error')}
              >
                <RichTextEditor
                  onChange={(value: string) => setRichText(value)}
                />
              </Form.Item>
            </Col>

            <Col lg={24} sm={24} xs={24}>
              <Form.Item
                name='popup'
                valuePropName='checked'
                initialValue={false}
                help={errors.popup && (errors.popup)}
                validateStatus={errors.popup && ('error')}
              >
                <PopUpCheckbox onChange={(e) => setShowLevel(e.target.checked)} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button
              loading={loading}
              className="mt-3"
              type="primary"
              htmlType="submit"
            >
              Enviar
            </Button>
          </Row>
        </Form>
      </div>
    </Content>
  )
}
