import { useForm } from 'antd/lib/form/Form'
import { IErrorDetailsECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import { swalErrorECPF } from 'components/SwalErrorECPF/SwalErrorECPF'
import { useProposalFlow } from 'ecpf/hooks/useProposalFlow'
import ProposalFuncaoModel, { IProposalSimulationMinMaxEcpf, ProposalSimulationFuncaoModel } from 'ecpf/models/ProposalFuncaoModel'
import { ProposalService, ConvenantLimitNotFound, ConditionalCovenant, CovenantNotFound } from 'ecpf/services/proposalService'
import { useMemo, useState } from 'react'
import { IReduxProposalFlow } from 'store/modules/proposalFlow/actions'
import { returnProposalType } from '../ProposalECPFFunctions'
import { ISimulationECPFBody, MetaCalculo, ProposalType } from '../ProposalECPFInterfaces'
import { useProposalContextECPF } from './useProposalContextECPF'
import useSimulationECPF from './useSimulationECPF'

export const useSimulationFlowEcpf = () => {
  const { updateSimulationFlow, updateSimulationFlowMinMax, customerFlow, clientCovenant, selectContractsFlow, getClientCovenantConditional, getClientCovenant, simulationFlowMinMax, client } = useProposalFlow()
  const { proposalType } = useProposalContextECPF()
  const [formRef] = useForm<ISimulationECPFBody>()

  const { onSimulation, loadingSubmit, setLoadingSubmit } = useSimulationECPF()

  const [error, setError] = useState<IErrorDetailsECPF>()
  const [loading, setLoading] = useState(false)

  const [loadingMaxValues, setLoadingMaxValues] = useState(false)
  const [metaCalculo, setMetaCalculo] = useState<MetaCalculo>(MetaCalculo.ValorParcela)

  const parcelasDisponiveis = useMemo(() => {
    return new Array(simulationFlowMinMax?.maxQuantidadeParcelas || ProposalSimulationFuncaoModel.DEFAULT_MAX_INSTALLMENTS).fill('').map((_, idx) => ({
      label: (idx + 1) + 'x',
      value: idx + 1
    }))
  }, [simulationFlowMinMax?.maxQuantidadeParcelas])

  const onSimulationHandle = async (simulation: ISimulationECPFBody, abortController: AbortController) => {
    try {
      const creditoFactory = await onSimulation(simulation, abortController)
      updateSimulationFlow(creditoFactory)
      return creditoFactory
    } catch (err) {
      if (!abortController.signal.aborted) {
        swalErrorECPF({ error: err })
        setLoadingSubmit(false)
      }
    }
  }

  const onResetError = () => {
    setError(undefined)
  }

  const onUpdateMinMaxWithoutLimits = (limit: number = ProposalSimulationFuncaoModel.DEFAULT_MAX_INSTALLMENTS) => {
    updateSimulationFlowMinMax(
      ProposalSimulationFuncaoModel.generateMinMaxSimulation(
        0,
        0,
        limit,
        { itens: [] }
      )
    )
  }

  const onProceedWithoutLimits = () => {
    onResetError()
    if (!simulationFlowMinMax) onUpdateMinMaxWithoutLimits()
  }

  const onFinish = async ({
    simulation,
    abortController,
    simulationFlowMinMax
  }:{
    simulation: ISimulationECPFBody,
    abortController: AbortController,
    simulationFlowMinMax?: IProposalSimulationMinMaxEcpf
  }): Promise<void> => {
    const formatedValues = ProposalSimulationFuncaoModel.formatToSend({
      condicaoCredito: simulation.condicaoCredito,
      customerFlow,
      clientCovenant,
      proposalType,
      selectContractsFlow,
      despesas: simulationFlowMinMax?.despesas,
      client,
      saldoDevedor: simulation.saldoDevedor
    })

    const simulationRes = await onSimulationHandle(formatedValues, abortController)
    const clientCovenantConditional = getClientCovenant(simulationRes?.condicoes)

    if (clientCovenantConditional && clientCovenantConditional.despesas !== null && simulationFlowMinMax?.despesas.itens.length === 0) {
      const updatedMinMax = { ...simulationFlowMinMax, despesas: { itens: clientCovenantConditional.despesas.itens } }

      updateSimulationFlowMinMax(updatedMinMax)

      return onFinish({
        simulation: formatedValues,
        abortController,
        simulationFlowMinMax: updatedMinMax
      })
    }
  }

  const onChangeMetaCalculo = (value: MetaCalculo) => {
    setMetaCalculo(value)

    const conditional = getClientCovenantConditional(proposalType)

    formRef.setFieldsValue({
      condicaoCredito: {
        ...conditional ?? {},
        metaCalculo: value
      }
    })
  }

  const onUpdateMinMaxByQuantidadeParcelas = async (
    quantidadeParcelas: number,
    currentValorParcela: number,
    currentValorSolicitado: number,
    abortController: AbortController
  ) => {
    try {
      setLoadingMaxValues(true)
      const proposalService = new ProposalService()

      const fakeCondicao = {
        metaCalculo: MetaCalculo.ValorSolicitado,
        valorParcela: 1000000,
        quantidadeParcelas
      }

      const fakeLimit = {
        idade: 0,
        valorMaximoFinanciado: 1000000,
        prazoMaximo: quantidadeParcelas,
        valorRiscoMaximo: 1000000
      }

      const simulationLimits = await proposalService.getSimulationLimits(
        {
          customerFlow,
          clientCovenant,
          proposalType,
          prazoMaximo: fakeLimit.prazoMaximo,
          condicao: fakeCondicao,
          config: { signal: abortController.signal },
          selectContractsFlow,
          client
        }
      )

      if (!simulationLimits || !simulationFlowMinMax?.maxQuantidadeParcelas) {
        throw new ConvenantLimitNotFound()
      }

      // The maxQuantidadeParcelas will change in this case
      const generateNewLimits = ProposalSimulationFuncaoModel.generateMinMaxSimulation(
        simulationLimits?.maxValorParcela,
        simulationLimits?.maxValorSolicitado,
        simulationFlowMinMax?.maxQuantidadeParcelas,
        simulationLimits?.despesas
      )

      updateSimulationFlowMinMax(generateNewLimits)
      setLoadingMaxValues(false)

      if (currentValorParcela > simulationLimits?.maxValorParcela) {
        formRef.setFieldsValue({
          condicaoCredito: {
            valorParcela: simulationLimits?.maxValorParcela
          }
        })
      }

      if (currentValorSolicitado > simulationLimits?.maxValorSolicitado) {
        formRef.setFieldsValue({
          condicaoCredito: {
            valorSolicitado: simulationLimits?.maxValorSolicitado
          }
        })
      }
    } catch (error) {
      if (error instanceof ConditionalCovenant) {
        console.error(error)
      } else {
        swalErrorECPF({ error })
        formRef.setFieldsValue({
          condicaoCredito: {
            quantidadeParcelas: parcelasDisponiveis[parcelasDisponiveis.length - 1].value
          }
        })
      }

      setLoadingMaxValues(false)
    }
  }

  const onFetchLimits = async (props: {
    customerFlow: IReduxProposalFlow['customerFlow']
    clientCovenant: IReduxProposalFlow['clientCovenant']
    proposalType: ProposalType
    saldoDevedor?: ISimulationECPFBody['saldoDevedor']
  }, abortController: AbortController) => {
    try {
      const {
        proposalType,
        customerFlow,
        clientCovenant
      } = props || {}

      setLoading(true)
      const proposalService = new ProposalService()

      const convenio = clientCovenant?.convenios?.[0]
      const { codigoEmpregador } = clientCovenant?.origens || {}

      if (!convenio) throw new CovenantNotFound()

      const limit = await proposalService.getLimitByClientAge(
        codigoEmpregador ?? '',
        returnProposalType(proposalType),
        customerFlow,
        convenio,
        { signal: abortController.signal }
      )

      // If the complete limits is not found, we should update limits with prazoMaximo (Televendas)
      onUpdateMinMaxWithoutLimits(limit.prazoMaximo)

      const fakeCondicao = {
        metaCalculo: MetaCalculo.ValorSolicitado,
        valorParcela: 1000000,
        quantidadeParcelas: limit.prazoMaximo,
        codigoConvenio: convenio?.codigo
      }

      const simulationLimits = await proposalService.getSimulationLimits(
        {
          customerFlow,
          clientCovenant,
          proposalType,
          prazoMaximo: limit.prazoMaximo,
          condicao: fakeCondicao,
          config: { signal: abortController.signal },
          selectContractsFlow,
          client,
          saldoDevedor: props.saldoDevedor
        }
      )

      // Override the value if the simulationLimits exists
      updateSimulationFlowMinMax(simulationLimits)

      setError(undefined)
      setLoading(false)

      formRef.setFieldsValue({
        condicaoCredito: {
          valorSolicitado: simulationLimits?.maxValorSolicitado,
          valorParcela: simulationLimits?.maxValorParcela,
          quantidadeParcelas: limit.prazoMaximo
        }
      })
    } catch (error) {
      if (!abortController.signal.aborted) {
        if (error instanceof ConditionalCovenant) {
          console.error(error)
        } else {
          setError(error)
        }

        setLoading(false)
      }
    }
  }

  const onGetClientCovenantConditional = () => {
    const conditional = getClientCovenantConditional(proposalType)

    if (!conditional) {
      return undefined
    }

    const metaCalculo = ProposalFuncaoModel.translateMetaCalculoByKey(conditional?.metaCalculo as number ?? 1)
    const seguro = ProposalSimulationFuncaoModel.getSeguroInCondicaoCredito(conditional)
    formRef.setFieldsValue({
      condicaoCredito: {
        ...conditional ?? {},
        seguro,
        metaCalculo
      }
    })

    setMetaCalculo(metaCalculo)
  }

  return {
    onSimulationHandle,
    onFinish,
    onChangeMetaCalculo,
    onUpdateMinMaxByQuantidadeParcelas,
    onFetchLimits,
    onGetClientCovenantConditional,
    setLoading,
    setError,
    onProceedWithoutLimits,
    loading,
    loadingMaxValues,
    loadingSubmit,
    metaCalculo,
    formRef,
    parcelasDisponiveis,
    error
  }
}
