import { FormInstance, useForm } from 'antd/lib/form/Form'
import { ProposalCustomerFlowClientType } from 'ecpf/models/ProposalFuncaoModel'
import React, { createContext, useContext, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { IReduxProposalFlow } from 'store/modules/proposalFlow/actions'
import { ProposalType } from '../ProposalECPFInterfaces'

interface IProposalProviderContext {
  onPrevStep: () => void
  onNextStep: () => void
  currentStep: number
  onChooseStep: (step: number) => void
  onResetStep: () => void
  proposalType: ProposalType
  extraCustomerFlowFormRef: FormInstance<IReduxProposalFlow['extraCustomerFlow']>
  flowType: ProposalCustomerFlowClientType
}

export const ProposalContextECPF = createContext<IProposalProviderContext | undefined>({} as unknown as IProposalProviderContext)

function ProposalProviderContext ({ children }: { children: React.ReactNode }) {
  const { proposalType } = useParams<{ proposalType: ProposalType }>()
  const [currentStep, setCurrentStep] = useState(0)

  const [extraCustomerFlowFormRef] = useForm<IReduxProposalFlow['extraCustomerFlow']>()

  const location = useLocation<{ search?: { flowType: ProposalCustomerFlowClientType } }>()

  // For More informations, check the ProposalFuncaoModel
  const searchParams = new URLSearchParams(location.search)
  const flowType = searchParams.get('flowType') as ProposalCustomerFlowClientType

  const onPrevStep = () => setCurrentStep(prev => prev - 1)

  const onNextStep = () => {
    setCurrentStep(prev => prev + 1)
  }

  const onChooseStep = (step: number) => {
    setCurrentStep(step)
  }

  const onResetStep = () => {
    setCurrentStep(0)
  }

  const context = {
    currentStep,
    onNextStep,
    onPrevStep,
    onChooseStep,
    onResetStep,
    proposalType,
    extraCustomerFlowFormRef,
    flowType
  }

  return (
    <ProposalContextECPF.Provider value={context}>
      {children}
    </ProposalContextECPF.Provider>
  )
}

export default ProposalProviderContext

export const useProposalContextECPF = () => {
  const context = useContext(ProposalContextECPF)

  if (context === undefined) {
    throw new Error('useProposalContext must be used within ProposalProviderContext')
  }

  return context
}
